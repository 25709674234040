<template>
    <div>
        <a-spin :spinning="loading">
            <a-table
                :columns="colums"
                :dataSource="data"
                bordered
                :pagination="false">
                <template slot="title">
                    <a-input-search style="width:250px;" v-model="params.keywords" placeholder="输入小区名称搜索" enter-button="搜索" @search="page()"/>
                    <a-button icon="plus" type="primary" style="margin-left: 10px;" @click="$refs.saveVillage.showModal(null)">添加小区</a-button>
                </template>
                <template slot="image" slot-scope="text">
                    <img :src="text" style="width:100px;height:50px;"/>
                </template>
                <template slot="action" slot-scope="text, record">
                    <a-button size="small" type="primary" class="action" @click="$refs.saveVillage.showModal(record)">修改</a-button>
                    <a-popconfirm
                        :title="'确认' + (record.isDelete === 0 ? '删除' : '恢复')"
                        ok-text="确认"
                        cancel-text="取消"
                        @confirm="updateStatus(record)"
                        >
                        <a-button size="small" type="info" class="action">{{ record.isDelete === 0 ? '删除' : '恢复' }}</a-button>
                    </a-popconfirm>
                </template>
            </a-table>
            <a-pagination
                v-if="data.length > 0"
                showQuickJumper
                style="margin-top:10px;"
                :defaultCurrent="1"
                :total="total"
                :pageSize="params.pageSize"
                @change="pageChange"
            />
        </a-spin>
        <SaveVillage ref="saveVillage" @ok="page"/>
    </div>
</template>
<script>
import SaveVillage from './modules/SaveVillage'
const colums = [
    { title: '封面', dataIndex: 'image', scopedSlots: { customRender: 'image' }},
    { title: '小区名称', dataIndex: 'name'},
    { title: '单价', dataIndex: 'unitPrice', scopedSlots: { customRender: 'unitPrice' }},
    { title: '在售房源', dataIndex: 'onSale'},
    { title: '在租房源', dataIndex: 'onLease', scopedSlots: { customRender: 'onLease' }},
    { title: '住宅类型', dataIndex: 'landType', scopedSlots: { customRender: 'landType' }},
    { title: '物业名称', dataIndex: 'property', scopedSlots: { customRender: 'property' }},
    { title: '详细地址', dataIndex: 'address', scopedSlots: { customRender: 'address' }},
    { title: '操作', scopedSlots: { customRender: 'action' } }
]
export default {
    components: {
        SaveVillage
    },
    data () {
        return {
            loading: false,
            data: [],
            defaultLogo: require('@/assets/default_logo.png'),
            colums,
            total: 0,
            params: {
                pageNum: 1,
                pageSize: 10,
                keywords: null
            }
        }
    },
    mounted () {
        this.page()
    },
    methods: {
        page () {
            let keywords = -1
            if (this.params.keywords !== '' && this.params.keywords !== null ) {
                keywords = this.params.keywords
            }
            this.loading = true
            this.$api.village.page(this.params.pageNum, this.params.pageSize, keywords).then(res => {
                if (res.status === 0) {
                    this.data = res.data.list
                    this.total = res.data.total
                } else {
                    this.$message.error(res.msg)
                }
            }).catch(err => {
                this.$message.error(err)
            }).finally(_ => {
                this.loading = false
            })
        },
        modify (school) {

        },
        pageChange (pageNum) {
            this.params.pageNum = pageNum
            this.page()
        }
    }
}
</script>
<style scoped>
    .action {
        margin-right: 10px;
    }
</style>

